require('./bootstrap');


import Alpine from 'alpinejs';
//import { drop } from 'lodash';
import * as helpers from './helpers.js';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'select2';
import { Pagination } from 'swiper';

window.Alpine = Alpine;

Alpine.start();

/**
 * Rajout de select2 pour les champs ville
 */

jQuery(function() {
    $('#ville').select2();
});

/**
 * If the user scrolls down 85px, add the class 'bg-black' to the navbar. If the
 * user scrolls back up, remove the class 'bg-black'
 */
function change_nav_color() {

    let nav = document.getElementById("nav-header");

    if (document.body.scrollTop >= 85 || document.documentElement.scrollTop >= 85 ) {
        nav.style.setProperty('--bg-color', "#000");
    } else {
        nav.style.setProperty('--bg-color', "transparent");
    }

}


/**
 * When a user clicks on a form section title, the next element sibling is toggled
 * to be visible.
 */
function dropdown_form() {

    let dropdown = document.querySelectorAll(".dropdown .form-section-title");

    dropdown.forEach(function (drop) {

        drop.addEventListener('click', function(e) {

            e.stopPropagation();

            // e.target.classList.add('open');
            let dropdown = e.target.nextElementSibling;

            helpers.toggle_attr(dropdown,'style','initial')
            dropdown.parentNode.classList.toggle('open');
        })
    });

    document.onclick = function(event) {

        let dropdown = document.querySelectorAll(".dropdown .form-list");


        dropdown.forEach(function (list) {

            if ( list.hasAttribute('style')) {
                list.removeAttribute('style');
            }

            let opened = document.querySelectorAll('.dropdown.open');
            opened.forEach(function(elem){
                elem.classList.remove('open');
            });
        })
    }
}

/**
 * It changes the title of the dropdown menu when a user clicks on one of the
 * options
 */
function change_title_dropdown() {

    let containers = document.querySelectorAll(".dropdown .container-input label");

    containers.forEach(function (container) {

        container.addEventListener('click', function(e) {

            let title    = this.innerHTML;
            let dropdown = this.parentNode.parentNode;
            let formtitle = dropdown.previousElementSibling;
            formtitle.innerHTML = title;

            helpers.toggle_attr(dropdown,'style','initial')

        })

    });

}

/**
 * > When the user clicks on the `#event-filter` element, toggle the `style`
 * attribute of the `#search-form .filters` element
 */
function toggle_filters_search() {

    let toggle = document.getElementById('event-filter')
    let filters = document.querySelector('#search-form .filters')

    toggle.addEventListener('click', function(e) {

        helpers.toggle_attr(filters,'style','flex')

        if(toggle.firstElementChild.innerText == '-') {
            toggle.firstElementChild.innerText = '+';
        } else {
            toggle.firstElementChild.innerText = '-';
        }
    })
}

function ajax_pagination_filter() {

    document.querySelectorAll('#pagination a').forEach(function (link) {

        link.addEventListener('click', function(e) {

            let inputOrder = '';
            let inputSecteur = '';
            let radioChecked = document.querySelector('#search-filter #order-list input[type="radio"]:checked');
            let secteurChecked = document.querySelector('#search-filter #secteur-list input[type="radio"]:checked');
            let inputChecked = document.querySelectorAll('#search-filter .input-filter input[type="checkbox"]:checked');
            let selected = Array.from(inputChecked).map(x => x.value)
            let formData = helpers.serializeJSON(document.querySelector('#search-form form'));

            if(radioChecked) {
                inputOrder = radioChecked.value;
            }

            if(secteurChecked) {
                inputSecteur = secteurChecked.value;
            }

            formData.prix = selected;
            formData.order = inputOrder;
            formData.secteur = inputSecteur;

            e.preventDefault()

            let url = new URL(this.getAttribute('href'))
            formData.page = url.searchParams.get('page')
            const u = new URLSearchParams(formData).toString();

            axios.get('/filtres?'+u)
                    .then((response)=>{
                        document.getElementById('results-annonces').innerHTML = response.data.view
                        document.getElementById('count-result').innerHTML = response.data.count
                        document.querySelector('#payantlabel span').innerHTML = response.data.payant
                        document.querySelector('#donlabel span').innerHTML = response.data.don
                        ajax_pagination_filter()
                    }).catch(error => {
                        console.log(error);
                    })
            //}
        })
    })

}

if(document.getElementById('search-form')) {
    toggle_filters_search();
}

dropdown_form();
change_title_dropdown();

let uri = location.pathname;
if(uri == ('/annonces') || uri ==('/') || uri ==('/qui-sommes-nous')) {
    window.addEventListener("scroll", change_nav_color);
}

/* A function that changes the name of the image when a user selects an image. */
if(document.body.classList.contains('info-user')) {

    let input = document.getElementById("avatar");
    let imageName = document.getElementById("image-name")
    if(input) {

        input.addEventListener("change", ()=> {

            let inputImage = document.querySelector("input[type=file]").files[0];
            imageName.innerText = inputImage.name;

        })
    }
}

if(document.body.classList.contains('edit-annonce')) {

    let inputImageAnnonce = document.querySelectorAll('.annonceimg');

    inputImageAnnonce.forEach(function (input) {

        input.addEventListener("change", (e)=> {
            let inputImage = input.files[0];
            input.parentElement.nextElementSibling.innerText = inputImage.name;
        })

    })
}

let modalSave = document.getElementById('save-modal');

/* A function that filters the results of the search. */
if(document.body.classList.contains('guest-recherche')) {

    let formData = helpers.serializeJSON(document.querySelector('#search-form form'));
    let inputFilters = document.querySelectorAll('#search-filter input');
    let btnSave = document.querySelectorAll('.save.auth');

    inputFilters.forEach(function (inputFilter) {

        inputFilter.addEventListener('click', function(e) {

            window.history.pushState({}, document.title, "/" + "annonces");

            let inputOrder = '';
            let inputSecteur = '';
            let radioChecked = document.querySelector('#search-filter #order-list input[type="radio"]:checked');
            let secteurChecked = document.querySelector('#search-filter #secteur-list input[type="radio"]:checked');
            let inputChecked = document.querySelectorAll('#search-filter .input-filter input[type="checkbox"]:checked');
            let selected = Array.from(inputChecked).map(x => x.value)

            if(radioChecked) {
                inputOrder = radioChecked.value;
            }

            if(secteurChecked) {
                inputSecteur = secteurChecked.value;
            }

            formData.prix = selected;
            formData.order = inputOrder;
            formData.secteur = inputSecteur;

            axios.post('/filtres', formData)
                .then((response)=>{
                    document.getElementById('results-annonces').innerHTML = response.data.view
                    document.getElementById('count-result').innerHTML = response.data.count
                    document.querySelector('#payantlabel span').innerHTML = response.data.payant
                    document.querySelector('#donlabel span').innerHTML = response.data.don
                    document.getElementById('pagination').classList.add('filter');

                    ajax_pagination_filter();


                }).catch(error => {
                    console.log(error);
                })
        })
    })

    btnSave.forEach(function (btn) {

        btn.addEventListener('click', function(e) {

            let inputOrder = '';
            let radioChecked = document.querySelector('#search-filter input[type="radio"]:checked');
            let inputChecked = document.querySelectorAll('#search-filter .input-filter input[type="checkbox"]:checked');
            let selected = Array.from(inputChecked).map(x => x.value)

            if(radioChecked) {
                inputOrder = radioChecked.value;
            }

            formData.prix = selected;
            formData.order = inputOrder;

            axios.post('/savesearch', formData)
                .then((response)=>{
                    btn.disabled = true;
                    modalSave.classList.add('open');
                }).catch(error => {
                    console.log(error);
                })
        })
    })
}

/* A function that opens a modal when a user clicks on a button. */
let btn_modal = document.querySelectorAll('.open-modal');
let modal = document.getElementById('modal');
let modalFav = document.getElementById('favoris-modal');
let close = document.querySelector('.close-modal');
let pageAnnonce = document.getElementById('mes-annonces');

if (btn_modal.length > 0 || modalFav) {

    window.onclick = function(event) {
        if ( modal) {
            if (event.target.classList.contains('modal-wrapper')) {
                modal.classList.remove('open');
            }
        }

        if ( modalFav) {
            if (event.target.classList.contains('modal-wrapper')) {
                modalFav.classList.remove('open');
            }
        }

        if ( modalSave) {
            if (event.target.classList.contains('modal-wrapper')) {
                modalFav.classList.remove('open');
            }
        }
    }

    btn_modal.forEach(function (btn) {
        btn.addEventListener('click', function() {
            modal.classList.add('open');
            if(pageAnnonce) {
                document.getElementById('annonce').value = this.dataset.id;
            }
        });
    });

    if(close) {
        close.addEventListener('click', function() {
            modal.classList.remove('open');
        });
    }
}

let checkImmediat = document.getElementById('immediatement');
if(checkImmediat) {

    if (checkImmediat.checked){
        document.getElementById('debut').readOnly = true;
    }

    checkImmediat.addEventListener('click', function() {

        if(checkImmediat.checked){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            document.getElementById('debut').value = today;
            document.getElementById('debut').readOnly = true;
        } else {
            document.getElementById('debut').readOnly = false;
        }

    })

}

let telAction = document.getElementById('tel-action');
if(telAction) {

    telAction.addEventListener('click', function() {

        telAction.innerHTML = telAction.dataset.tel

    })

}

/* It's a function that adds an event listener to the `.whislist` elements.
When a user clicks on one of these elements, the `iwishthat` route is called. */
let iWish = document.querySelectorAll('.whislist');
let token = document.head.querySelector('meta[name="csrf-token"]');
if (iWish.length > 0) {

    iWish.forEach(function (btn) {

        btn.addEventListener('click', function(e) {
            e.preventDefault();

            let formData        = {};
                formData.id     = this.dataset.id;
                formData._token = token.content;
            axios.post('/iwishthat', formData)
            .then((response)=>{
                btn.classList.toggle('active');
                modalFav.classList.add('open');
                document.querySelector('#favoris-modal .title').innerText = response.data.titre
            }).catch(error => {
                if(error.response.status == 401) {
                    window.location.href = "/connexion";
                    //console.log(error.response.status);
                }
            })
        });
    });
}

let myModalBtn = document.getElementById('btnMyModal'),
    myModal = document.getElementById('myModal'),
    closeMyModalBtn = document.getElementById('closeMyModalBtn');

    if(myModalBtn && myModal && closeMyModalBtn) {
        myModal.style.display = 'none';

        myModalBtn.addEventListener('click', (e) => {
            e.preventDefault();
            if(myModal.style.display === 'none'){
                myModal.style.display = 'block';
                console.log('block');
            }
            else {
                myModal.style.display = 'none';
                stopAllYouTubeVideos();
            }
        });
        closeMyModalBtn.addEventListener('click', (e) => {
            // e.preventDefault();
            if(myModal.style.display === 'none'){
                myModal.style.display = 'block';
                console.log('block');
            }
            else {
                myModal.style.display = 'none';
                stopAllYouTubeVideos();
            }
        });


    }

    var stopAllYouTubeVideos = () => {
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => {
        iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
        func: 'stopVideo' }), '*');
        });
        }


    let page = location.pathname;
    if( page == ('/mon-compte/mes-annonces') && document.getElementById('vendu')) {

        var submit = document.querySelectorAll('#modal form input[type=submit]');

        submit[0].addEventListener('click', () => {
            window.dataLayer = window.dataLayer || [];
            // dataLayer.push({'venteBam': document.getElementById('vendu').checked });
            if (document.getElementById('vendu').checked){
                dataLayer.push({'event': 'VenduAvecLaBam' });
            }
        });
    }
